/* eslint-disable no-loop-func */
/* eslint-disable no-unused-vars */
import React from "react";
import Table from "examples/Tables/Table";
/*
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
*/
import { useState } from 'react';
import Button from '@mui/material/Button';
import { useHistory } from "react-router-dom";
import { ThemeProvider } from '@emotion/react';
import theme from "assets/theme";
import ModalProjectTemplateConfig from "./ModalProjectTemplateConfig";
import { updateStatusProjectAdmin } from '../api/requestprojectpayment.api';
import { updateProjectDeployedCode } from '../api/project.api';
import ModalInfoPartner from "./ModalInfoPartner";
import { updateStatusPartnerAdmin } from "api/partner.api";

function DynamicTable({ data, token, haveComponent, component }) {
	const headers = Object.keys(data[0]);
	let history = useHistory()
	const [dataTable, setDataTable] = useState(data);
	const [selectedValue, setSelectedValue] = useState();
	const [selectedPartner, setSelectedPartner] = useState();
	const [openDialogPartner, setOpenDialogPartner] = useState();
	const [openDialogTemplate, setOpenDialogTemplate] = useState(false); 
	const columns = [];
	const rows = [];
	const openTemplate = (data) => {
		setSelectedValue(data);
		setOpenDialogTemplate(true);
	}
	async function updateProject(data) {
		try {
			await updateProjectDeployedCode({
				token, idProject: data._id
			});
			alert('Se actualizo de manera exitosa');
		} catch (error) {
			alert('Error al procesar la petición');
		}
	}
	async function updatePartner(data, statusPartner) {
		try {
			await updateStatusPartnerAdmin({
				token,
				_id: data._id,
				status: statusPartner,
			})
		} catch (error) {
			alert('Error al procesar la petición');
		}
	}

	function handleOpenPartner(data) {
		try {
			setSelectedPartner(data);
			setOpenDialogPartner(true);
		} catch (error) {
			alert('Error al capturar dato');
		}
	}
	for (let index = 0; index < headers.length; index++) {
		columns.push({name: headers[index], align: 'left'})
	}
	for(let index = 0; index < data.length; index++){
		var temp = {};
		temp = dataTable[index];
		if(temp['_id'] && (temp['slug'] || temp['lastName'])){
			temp['view'] = (<Button onClick={()=>checkClick(data[index])}>View</Button>);
		}
		if (history.location.pathname === '/projects') {
			temp['template'] = (<Button onClick={()=>openTemplate(data[index])}>Template</Button>);
		}
		if (history.location.pathname === '/partners') {
			temp['visualize'] = (<Button onClick={()=>handleOpenPartner(data[index])}>Visualizar</Button>);
			if (data[index]?.active === 'No') {
				temp['updatepartner'] = (<Button onClick={()=>updatePartner(data[index], true)}>Actualizar Estado</Button>);
			}
			if (data[index]?.active === 'Si') {
				temp['updatepartner'] = (<Button onClick={()=>updatePartner(data[index], false)}>Desactivar Afiliado</Button>);
			}
		}
		if (temp['deployed']) {
			temp['actions'] = (
				<>
					{data[index]?.deployed === 'Si' && (
						<Button onClick={()=>updateProject(data[index])}>Update</Button>
					)}
				</>
			);
		}
		if (history.location.pathname === '/requestprojectpayment') {
			temp['view'] = (
				<>
					{
						(temp.status === 'waiting' || temp.status === 'cancel') && (
							<Button
								style={{
									marginRight: 5,
									marginLeft: 5
								}}
								onClick={async () => {
									try {
										await updateStatusProjectAdmin({
											token, status: 'success', _id: temp._id
										})
										alert('Se actualizo de manera exitosa');
										window.location.reload();
									} catch (error) {
										console.log(error);
										if (error.info) {
											alert(error.info);
										} else {
											alert('Ocurrio un error al procesar la solicitud')
										}
									}
								}}
							>
								Habilitar
							</Button>
						)
					}
					{
						(temp.status === 'waiting' || temp.status === 'success') && (
							<Button
								style={{
									marginRight: 5,
									marginLeft: 5
								}}
								onClick={async () => {
									try {
										await updateStatusProjectAdmin({
											token, status: 'success', _id: temp._id,
										})
										alert('Se actualizo de manera exitosa');
										window.location.reload();
									} catch (error) {
										console.log(error);
										if (error.info) {
											alert(error.info);
										} else {
											alert('Ocurrio un error al procesar la solicitud')
										}
									}
								}}
							>
								Deshabilitar
							</Button>
						)
					}
				</>
			)
		}
		rows.push(temp);
	}
	const checkClick = (value)=>{
		// console.log(value)
		const stateValue = [];
		for (var key in value) {
			if(key !== 'view'){
				stateValue.push(value[key]);
			}
		}
		
		if(value.slug){
			history.push({ pathname: `/project/${value._id}`, state: stateValue})
		}
		if(value.lastName){
			history.push({ pathname: `/profile/${value._id}`, state:  stateValue })
		}
		
	}

	return (
		
		<div>
			<ModalProjectTemplateConfig
				open={openDialogTemplate}
				onClose={() => setOpenDialogTemplate(false)}
				projectInfo={selectedValue}
				token={token}
			/>
			<ModalInfoPartner
			
			/>
			<ThemeProvider theme={theme}>
			{
				data.length > 0 ? (
					<Table
						columns = {columns}
						rows = {rows}
					/>
				) : (
					<h2 className="text-center mt-3 mb-3">
						No hay datos
					</h2>
				)
			}
			</ThemeProvider>
		</div>
	);
}


export default DynamicTable;